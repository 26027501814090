import React from 'react'
import classnames from 'classnames'


const Selection = ({ content }) => {
  return (
    <div className="selection-wrapper">
      <div className="container">
        <p className="title"> {content.title} </p>
        <p className="subheading"> {content.subheading} </p>

        <div className="selection-list">
          {
            content.selections.map((selection, i) => {
              return (
                <div key={i}
                  className={classnames('selection-item', {
                    xs: selection.imgSize === 'xsmall',
                    sm: selection.imgSize === 'small',
                    med: selection.imgSize === 'medium',
                    lrg: selection.imgSize === 'large',
                    auto: selection.imgSize === 'auto'
                  })}
                  style={{
                    backgroundImage: `url(${selection.image})`,
                    backgroundSize: selection.size,
                  }}
                >
                  { selection.withLabel && <div className="card-ribbon">{selection.btnText}</div> }
                  {/* <button className={classnames('flag btn-gray btn-transparent')}>{selection.btnText}</button> */}
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}

export default Selection