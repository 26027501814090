import React from 'react'

import Layout from '../../../components/layout'
import SEO from "../../../components/seo"
import TwoColumn from "../../../components/two-column"
import CustomerJourney from '../../../components/customer-journey'
import Selection from '../../../components/selection'
// import { graphql, useStaticQuery } from 'gatsby'
// import Img from 'gatsby-image'

const setupConnected = {
  title: "Easy and secure to get setup and connected.",
  list: [
    {
      title: 'Secure account linking',
      description: <>Our accounting integrations will securely link to your preferred solution, transferring data safely and quickly.</>,
    },
    {
      title: 'Easy to map accounts',
      description: <> We'll make it really easy to map your Billsby invoices, credit notes, refunds and bad debts with the accounts you'd like to use in your accounting package.</>,
    },
    {
      title: 'No extra charges ',
      description: <>We know accounting integrations are a core feature, so we wouldn't dream of requiring you to upgrade to a premium plan to use them.</>,
    }
  ],
  image: 'get-setup.png',
  srcSet: 'get-setup@2x.png 2x',
  imageName: 'get-setup png ',
}

const clearlyDocumented = {
  title: "Clearly documented with detailed in-app support.",
  list: [
    {
      title: 'No guesswork here',
      description: <>We'll clearly explain all the possible billing scenarios so you know exactly how they'll be represented in your accounting software.</>,
    },
    {
      title: 'We\'re ready to assist',
      description: <> We build all of our accounting integrations in-house, so if you need support or assistance, we're just a click away.</>,
    },
    {
      title: 'Constantly up-to-date',
      description: <>We're always monitoring our integrations to make sure they'll work seamlessly with your accountancy package of choice.</>,
    }
  ],
  image: 'clearly-documented.png',
  srcSet: 'clearly-documented@2x.png 2x',
  imageName: 'clearly-documented png',
  align: 'right',
}

const PaymentSelection = {
  title: 'Whichever accounting software you use, you can do the books with us.',
  subheading: 'Each of our accounting integrations has the same great features.',
  selections : [
    {
      image: require('../../../images/freeagent.png'),
      srcSet: 'freeagent@2x.png',
      imageName: 'freeagent.com',
      imgSize: 'medium',
      btnText: 'AVAILABLE NOW'
    },
    {
      image: require('../../../images/quickbooks.svg'),
      imageName: 'Quickbooks',
      imgSize: 'medium',
      btnText: 'AVAILABLE NOW'
    },
    {
      image: require('../../../images/xero_coming.png'),
      srcSet: 'xero@2x.png 2x',
      imageName: 'xero',
      imgSize: 'auto',
      btnText: 'COMING LATE 2021',
      withLabel: true,
    },
  ]
}

const Accounting = () => {

  const bannerContent = {
    title: "Get your books done for you with our accounting integrations.",
    description: [
      {
        text: 'We\'re working on building accounting integrations so you can get the books done without having to lift a finger - with full synchronisation of invoices, credit notes and all of your other transactions.'
      }
    ],
    isBanner: true,
    sectionAccounting: true,
    textColor: "#252422",
    backgroundColor: "#F3F5FB",
    image: require('../../../images/accounting.svg'),
    svg: true,
    srcSet: "accounting@2x.svg 2x",
    imageName: 'accounting svg',
    imagePosition: 'right',
    buttons: [
      {
        url: 'https://app.billsby.com/registration',
        isExternal: true,
        buttonText: 'Sign up today',
        buttonColor: 'orange'
      },
      {
        scheduleButton: true
      }
    ],
    tags: [
      // {
      //   class: 'adoric_roadmap btn-coming-soon',
      //   text: "COMING SOON",
      //   iconDisable: true,
      // }
    ],
  }

  return (
    <Layout className="accounting">
      <SEO
        title="Recurring billing accounting integrations | Billsby"
        description="Easy-to-integrate recurring billing and subscription management software with accounting reports for gym, travel, education & healthcare businesses | Billsby"
        url="https://www.billsby.com/product/integrations/accounting"
      />

      <TwoColumn content={bannerContent} />
      <CustomerJourney content={setupConnected} />
      <CustomerJourney content={clearlyDocumented} />
      <Selection content={PaymentSelection} />
    </Layout>
  )
}

export default Accounting


// export  const query = graphql`
//   query Images {
//     image: file(relativePath: {eq: "blog-banner.jpg"}) {
//       childImageSharp {
//         fixed (width: 400) {
//           ...GatsbyImageSharpFixed
//         }
//       }
//     }
//   }
//   `